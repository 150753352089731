import React, { Component } from 'react';

class Reviews extends Component {
	render() {
		return (
			<div id="reviews-section">
				<div className="container">
					<div className="content">
						<h1>Our Customers Love Us!</h1>
						<div className="reviews">
							<blockquote>
								This place looks amazing!! Finally decided to give it a second
								shot after 5 months to find out the management/owner
								changed...thank God!! Its so clean and welcoming inside.
								Employees are friendly and the food is DELICIOUS! Thanks for the
								awesome customer service and scrumptious food! Will definitely
								be coming a lot!!
								<span>- Noshy S.</span>
							</blockquote>
							<blockquote>
								Ever since this location got new management and new employees,
								its gotten much better! The food is always made fresh and it's
								delicious! Everyone who works there is much nicer and
								accommodating. The interior of this location is much cleaner and
								the renovations make this a great place to eat!
								<span>- Brittany H.</span>
							</blockquote>
							<blockquote>
								The new management has definitely done a GREAT job in cleaning
								up the place. And there food is GREAT compared to what it was
								prior to them taking over. Everyone should give them another
								chance.
								<span>- Dionne V.</span>
							</blockquote>
							<blockquote>
								Visited with my friends few days ago, and to be honest the meal
								and service was awesome. The food there is too delicious! i
								loved it ! and i bet other will like it too. The management of
								this store is really good, people should visit there!
								<span>- Phil S.</span>
							</blockquote>
							<blockquote>
								Delicious!!! Personally, I prefer the fried chicken here over
								Popeyes. JJ's taste more like home style chicken. Also, the
								Staff is Awesome!
								<span>- Johnson S.</span>
							</blockquote>
							<blockquote>
								This restaurant is surely one of the most pleasant place to eat
								in the area. Whenever I go there I am very glad. They hold their
								very high standard service and the topmost level of meals they
								provide. You surely will enjoy this this place. Warmly
								recommended.
								<span>- Robert G.</span>
							</blockquote>
							<blockquote>
								TGreat Wings and Chicken strips, Great Shrimp and The Catfish
								Tails were cooked to perfection! My kids and I liked this place
								over the competition! They WIN! Even gave us extra lemon pepper!
								Our spot!!!
								<span>- Carolynn P.</span>
							</blockquote>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Reviews;
