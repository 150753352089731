import React, { Component } from 'react';
import Slider from './Slider';
import Location from './Location';
import Welcome from './Welcome';
class Home extends Component {
	render() {
		return (
			<div className="homepage">
				<Slider />
				<Welcome />
				<Location />
			</div>
		);
	}
}

export default Home;
