import React, { Component } from 'react';
import Header from './components/Header';
import {BrowserRouter, Route} from 'react-router-dom';
import Home from './components/Home';
import Menu from './components/Menu';
import Reviews from "./components/Reviews";
import Contact from "./components/Contact";
import Footer from "./components/Footer"
import '../src/App.scss'

class App extends Component {
  render(){
    return (
      <BrowserRouter>
        <div className="App">
          <Header />
          <Route exact path='/' component={Home} />
          <Route path='/menu' component={Menu} />
          <Route path='/reviews' component={Reviews} />
          <Route path='/contact' component={Contact} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
