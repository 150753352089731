import React, { Component } from 'react';

class Menu extends Component {
	render() {
		return (
			<div className="menu">
				<div className="chicken-menu">
					<div className="img-container">
						<img alt="chicken menu" src="/chicken-menu.jpg" />
					</div>
				</div>
				<div className="fish-menu">
					<div className="img-container">
						<img alt="chicken menu" src="/fish-menu.jpg" />
					</div>
				</div>
				<div className="combo-menu">
					<div className="img-container">
						<img alt="chicken menu" src="/combo.jpg" />
					</div>
				</div>
			</div>
		);
	}
}

export default Menu;
