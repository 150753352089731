import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
class Header extends Component {
	render() {
		return (
			<div id="headers">
				<div id="main-header" className="hidden-md">
					<div className="container">
						<nav className="nav-container">
							<ul className="top-menu">
								<li>
									<NavLink to="/" exact={true}>
										Home
									</NavLink>
								</li>
								<li>
									<NavLink to="/menu">Menu</NavLink>
								</li>
								<div>
									<Link to="/">
										<img src="/logo.jpg" alt="logo" />
									</Link>
								</div>
								<li>
									<NavLink to="contact">Contact Us</NavLink>
								</li>
								<li>
									<NavLink to="reviews">Reviews</NavLink>
								</li>
							</ul>
						</nav>
					</div>
				</div>
				<div id="mobile-header" className="visible-xs">
					<div className="container">
						<div className="mobile-nav">
							<div className="mobile-logo">
								<Link to="/">
									<img src="/logo.jpg" alt="logo" />
								</Link>
							</div>
							<div className="mobile-menu">
								<ul>
									<li>
										<NavLink to="/" exact={true}>
											Home
										</NavLink>
									</li>
									<li>
										<NavLink to="/menu">Menu</NavLink>
									</li>
									<li>
										<NavLink to="/contact">Contact Us</NavLink>
									</li>
									<li>
										<NavLink to="/reviews">Reviews</NavLink>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="fixed-top-spacer"></div>
			</div>
		);
	}
}

export default Header;
