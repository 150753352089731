import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

class Slider extends Component {
	render() {
		return (
			<div id="carousel-section">
				<Carousel autoPlay={true} infiniteLoop={true}>
					<div>
						<img src="/shrimp.jpg" alt="shrimp" />
						<p className="legend">Jumbo Shrimps</p>
					</div>
					<div>
						<img src="/tilapia.jpg" alt="tilapia" />
						<p className="legend">Tilapia</p>
					</div>
					<div>
						<img src="/wings.jpg" alt="wings" />
						<p className="legend">Chicken Wings</p>
					</div>
					<div>
						<img src="/nuggets.jpeg" alt="nuggets" />
						<p className="legend">Catfish Nuggets</p>
					</div>
				</Carousel>
			</div>
		);
	}
}

export default Slider;
