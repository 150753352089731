import React, { Component } from 'react';

class Welcome extends Component {
	render() {
		return (
			<div id="welcome-section">
				<div className="container">
					<div className="welcome-inner">
						<h1>Welcome to JJ Fish & Chicken</h1>
						<p>
							Bringing you the famous fried fish and chicken from the city to
							the burbs!
						</p>
					</div>
					<div className="welcome-outer">
						<div className="delivery">
							<div className="top">
								<span className="line"></span>
								<h1>Order for Deliver</h1>
								<span className="line"></span>
							</div>
							<div className="options">
								<a
									href="https://www.ubereats.com/store/super-jj-fish-%26-chicken/1gGnBtP6Uzm_bH5ZzR96mg?diningMode=DELIVERY&ps=1"
									target="_blank"
									rel="noopener noreferrer"
								>
									<aside className="uber">
										<img src="/uber.png" alt="uber" />
									</aside>
								</a>
								<a
									href="https://www.doordash.com/store/big-jj-fish-chicken-bolingbrook-397818/en-US/?utm_campaign=gpa"
									target="_blank"
									rel="noopener noreferrer"
								>
									<aside className="doordash">
										<img src="/doordash.jpg" alt="doordash" />
									</aside>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Welcome;
