import React, { Component } from 'react';

class Location extends Component {
	render() {
		return (
			<div id="info">
				<div className="map">
					<iframe
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2979.103390695571!2d-88.06869228431883!3d41.696703684724895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e5bb724675de1%3A0x8f92d4cba0b25abe!2s184%20S%20Bolingbrook%20Dr%2C%20Bolingbrook%2C%20IL%2060440!5e0!3m2!1sen!2sus!4v1603757733807!5m2!1sen!2sus"
						title="map"
						frameborder="0"
						tabindex="0"
						aria-hidden="false"
						allowfullscreen=""
					></iframe>
				</div>
				<div className="contact-info">
					<div className="container">
						<div className="contact-outer">
							<h1>
								Our Contact <span>INFO</span>
							</h1>
							<h2>We are at your service at the following location</h2>
							<div className="contact-inner">
								<h3>Address:</h3>
								<h2>
									184 S Bolingbrook Dr,
									<br />
									Bolingbrook, IL 60040
								</h2>
								<h3>Phone Number:</h3>
								<h2>(630) 783-9160</h2>
								<h3>Email:</h3>
								<h2>
									jjfishandchickenbolingbrook
									<br class="visible-xs" />
									@gmail.com
								</h2>
								<h3>Timing:</h3>
								<h2>
									Monday - Saturday: 11AM - 9PM
									<br />
									Sunday: 11AM - 8PM
								</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Location;
