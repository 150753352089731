import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return (
			<footer>
				<div className="conatiner">
					<div className="footer">
						<p>Copyright 2020 Ⓒ Naeem &amp; Qudsia Inc. | Created by NS</p>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
