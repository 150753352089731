import React, { Component } from 'react';

//
const encode = (data) => {
	return Object.keys(data)
		.map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
};

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			email: '',
			message: '',
			emptyError: '',
			successMessage: '',
		};
	}

	handleSubmit = (e) => {
		e.preventDefault();
		if (!this.state.name || !this.state.email || !this.state.message) {
			return this.setState({
				emptyError: 'One or more fields are empty. Please fill and try again.',
			});
		} else {
			fetch('/', {
				method: 'POST',
				headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
				body: encode({ 'form-name': 'contact', ...this.state }),
			}).then(() =>
				this.setState({
					name: '',
					email: '',
					message: '',
					emptyError: '',
					successMessage:
						'Your form has been submitted! Someone from our team will reach out to you promptly.',
				})
			);
		}
	};

	handleChange = (e) => this.setState({ [e.target.name]: e.target.value });

	render() {
		const { name, email, message, emptyError, successMessage } = this.state;
		return (
			<div className="contact-page">
				<div className="container">
					<div className="map">
						<iframe
							src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2979.103390695571!2d-88.06869228431883!3d41.696703684724895!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e5bb724675de1%3A0x8f92d4cba0b25abe!2s184%20S%20Bolingbrook%20Dr%2C%20Bolingbrook%2C%20IL%2060440!5e0!3m2!1sen!2sus!4v1603757733807!5m2!1sen!2sus"
							title="map"
							frameborder="0"
							tabindex="0"
							aria-hidden="false"
							allowfullscreen=""
						></iframe>
					</div>
					<div className="call">
						<h1>Call Us</h1>
						<a href="tel:+16307839160">(630) 783-9160</a>
					</div>
					<div className="title">
						<h1>Contact</h1>
						<h3>
							Have any questions, concerns or feedback? Let us know below!
						</h3>
					</div>
					<div className="content">
						<div className="errorMessage">
							<h1>{emptyError}</h1>
							<h1>{successMessage}</h1>
						</div>
						<div className="form">
							<form name="contact" onSubmit={this.handleSubmit}>
								<div className="top-column">
									<div className="first-section">
										<label htmlFor="name">Name</label>
										<input
											type="text"
											name="name"
											placeholder="Name"
											value={name}
											onChange={this.handleChange}
										/>
									</div>
									<div className="second-section">
										<label htmlFor="email">Email</label>
										<input
											type="email"
											name="email"
											placeholder="Email"
											value={email}
											onChange={this.handleChange}
										/>
									</div>
								</div>
								<div className="bottom-column">
									<label htmlFor="message">Message</label>
									<textarea
										name="message"
										className="textarea"
										placeholder="Message"
										value={message}
										onChange={this.handleChange}
									/>
								</div>
								<div className="button">
									<button type="submit">Submit</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;
